import isObject from 'isobject';

const appendFileToFormData = (data, key, file) => {
  data.append(key, file, file.name);
};

const modelToFormData = (model = null, formData = null) => {
  if (!model) throw new Error('Model is null');

  const keys = Object.keys(model);

  if (!keys.length) throw new Error('Model is empty');

  const data = formData || new FormData();

  keys.forEach((key) => {
    // TODO if key is object ({})
    if (Array.isArray(model[key])) { // если элемент модели - массив
      model[key].forEach((el, index) => {
        if (el instanceof File) { // если элемент массива -- файл
          appendFileToFormData(data, `${key}[${index}]`, el);
        } else if (isObject(el)) { // если элемент массив -- объект
          Object.keys(el).forEach((elKey) => {
            data.append(`${key}[${index}].${elKey}`, el[elKey]);
          });
        } else { // в остальных случаях
          data.append(`${key}[${index}]`, el);
        }
      });
    } else if (model[key] instanceof File) { // если файл лежит в корне модели
      appendFileToFormData(data, key, model[key]);
    } else if (model[key] != null) {
      data.append(key, model[key]);
    }
  });

  return data;
};

export default modelToFormData;
