<template>
  <div class="page">
    <esmp-table-wrapper
      class="news"
      title="Новости"
      :is-allow-full-page="false"
      :is-column-settings-button-showed="false"
    >
      <template #header-actions>
        <esmp-button
          @click="editOrCreateNewsItem(null)"
          size="small"
        >
          Создать
        </esmp-button>
      </template>
      <esmp-table
        :columns="columns"
        :rows="rows"
        :loading="loading"
        stripe
      >
        <template #cell-startDatetime="{ td }">
          {{ td | dateFormat('DD.MM.YYYY, HH:mm:ss') }}
        </template>
        <template #cell-finishDatetime="{ td }">
          {{ td | dateFormat('DD.MM.YYYY, HH:mm:ss') }}
        </template>
        <template #cell-isPublic="{ td, tr }">
          <esmp-switch
            :value="td"
            shape="circular"
            @input="(val) => isPublicChange(tr, val)"
          />
        </template>
        <template #cell-actions="{ tr }">
          <div class="news__actions">
            <span class="news__actions-item" @click="editOrCreateNewsItem(tr)">
              <esmp-icon name="doc-edit" />
            </span>
            <span class="news__actions-item" @click="showConfirmModal(tr)">
              <esmp-icon name="trash" />
            </span>
          </div>
        </template>
      </esmp-table>
    </esmp-table-wrapper>
    <esmp-pagination-adaptive
      class="news__pagination"
      :page-count="pageCount"
      :current-page.sync="currentPage"
    />
    <esmp-modal
      v-model="confirmModalShowed"
      class="modal-confirm"
      @on-ok="deleteNewsItem"
    >
      <template #header>
        Удаление элемента
      </template>
      Вы уверены?
    </esmp-modal>
    <esmp-modal
      v-model="editModalShowed"
      class="modal-edit"
      width="800"
      @on-ok="saveNewsItem"
      :loading="true"
    >
      <template #header>
        {{ formTitle }}
      </template>
      <validation-observer ref="form">
        <validation-provider
          rules="required|min:5|max:255"
          :name="`«Заголовок»`"
          v-slot="v"
          tag="div"
          class="form-item"
        >
          <esmp-input
            v-model="itemToEdit.title"
            label="Заголовок"
            :error-message="v.errors.length ? v.errors[0] : ''"
          />
        </validation-provider>
        <esmp-upload
          class="form-item news__main-img-wrapper"
          action="/api/files"
          type="drag"
          auto-upload
          :on-success="onUploadMainImage"
          :show-upload-list="false"
        >
          <span v-if="!itemToEdit.mainImageUrl">Изображение</span>
          <esmp-button
            v-if="itemToEdit.mainImageUrl"
            icon="trash"
            view="function"
            @click.stop="removeMainImage"
          />
          <img
            v-if="itemToEdit.mainImageUrl"
            :src="itemToEdit.mainImageUrl"
            class="news__main-img"
          >
        </esmp-upload>
        <wysiwyg
          class="form-item"
          v-model="itemToEdit.content"
        />
        <esmp-upload
          v-if="isEditMode"
          type="drag"
          class="form-item"
          v-model="itemToEdit.fileObj.files"
          @add="onEditModeAddFile"
          @delete="onEditModeDeleteFile"
        >
          Вложения
        </esmp-upload>
        <esmp-upload
          v-else
          type="drag"
          class="form-item"
          v-model="itemToEdit.fileObj.fileObjects"
        >
          Вложения
        </esmp-upload>
        <validation-provider
          class="form-item"
          :rules="{ required: true, datePeriod: { endDay: itemToEdit.finishDatetime } }"
          :name="`«Начало показа»`"
          v-slot="v"
          tag="div"
        >
          <esmp-datepicker-adaptive
            :key="String(itemToEdit.finishDatetime)"
            v-model="itemToEdit.startDatetime"
            placeholder="Начало показа"
            is-time-selectable
            :time-interval="1"
            :disabled-dates="minStartDate"
            clearable
          />
          <span v-if="v.errors.length" class="news__error typo-caption">{{ v.errors[0] }}</span>
        </validation-provider>
        <validation-provider
          class="form-item"
          :rules="{ required: true, datePeriod: { startDay: itemToEdit.startDatetime } }"
          :name="`«Завершение показа»`"
          v-slot="v"
          tag="div"
        >
          <esmp-datepicker-adaptive
            :key="String(itemToEdit.startDatetime)"
            v-model="itemToEdit.finishDatetime"
            placeholder="Завершение показа"
            :disabled-dates="minEndDate"
            is-time-selectable
            :time-interval="1"
            clearable
          />
          <span v-if="v.errors.length" class="news__error typo-caption">{{ v.errors[0] }}</span>
        </validation-provider>
        <esmp-select
          class="form-item"
          v-model="itemToEdit.serviceIds"
          filterable
          placeholder="Услуги"
          multiple
          :remote-method="searchServices"
          :loading="searchLoading"
        >
          <esmp-select-option
            v-for="item in servicesSearchResults"
            :value="item.id"
            :key="item.id"
          >
            {{ item.name }}
          </esmp-select-option>
        </esmp-select>

        <esmp-select
          class="form-item"
          v-model="selectedSubscriptionsNodes"
          placeholder="Группирующие узлы"
          multiple
          :loading="isSubscriptionBranchesLoading"
          @on-change="getNodeBranches"
        >
          <esmp-select-option
            v-for="item in nodeList"
            :value="item.id"
            :key="item.id"
          >
            {{ item.name }}
          </esmp-select-option>
        </esmp-select>

        <esmp-select
          v-if="isSubscriptionsBranchesSelectShowed"
          class="form-item"
          v-model="selectedSubscriptionsBranches"
          placeholder="Ветки"
          multiple
        >
          <esmp-select-option
            v-for="item in subscriptionsBranches"
            :value="item.id"
            :key="item.id"
          >
            {{ item.name }}
          </esmp-select-option>
        </esmp-select>

        <div class="form-item">
          <esmp-switch
            v-model="itemToEdit.isMain"
            shape="circular"
            label="Показывать на главной"
          />
          <esmp-switch
            class="ml-20"
            v-model="itemToEdit.isPublic"
            shape="circular"
            label="Опубликовать"
          />
        </div>
      </validation-observer>
    </esmp-modal>
  </div>
</template>

<script>
// TODO: разделить компонент на список и создание/редактирование
import { isArray, uniq } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import { mapActions } from 'vuex';
import Wysiwyg from '@/components/wysiwyg';
import convertDate from '@/helpers/convertDate';
import modelToFormData from '@/helpers/modelToFormData';
import { NEWS_PER_PAGE } from '@/constants/news';

const itemDefault = {
  title: '',
  mainImageUrl: '',
  content: '',
  startDatetime: null,
  finishDatetime: null,
  isPublic: true,
  isMain: false,
  serviceIds: [],
  nodes: [],
  subscriptions: [],
  fileObj: {
    files: [],
    addedFiles: [],
    fileObjects: [],
    deletedFilesIds: [],
  },
};

/**
 * Собирает массив подписок
 * @param nodes {array} - массив узлов первого уровня
 * @param branches {array} - массив узлов второго уровня (ветки)
 * @return {*[]} - собранные подписки
 */
const getMappedSubscriptions = (nodes, branches) => {
  if (!nodes || !isArray(nodes)) {
    throw new Error('getMappedSubscriptions: nodes должны быть массивом');
  }
  if (!branches || !isArray(branches)) {
    throw new Error('getMappedSubscriptions: branches должны быть массивом');
  }

  // если нет веток, а узлов любое кол-во
  if (!branches.length) {
    return nodes.map((node) => ({
      nodeId: node,
    }));
  }

  // если один узел, но много веток
  if (nodes.length === 1 && branches.length) {
    return branches.map((branch) => ({
      nodeId: nodes[0],
      branchId: branch,
    }));
  }

  return [];
};

/**
 * Разбирает подписки на узлы первого и второго уровня
 * @param subscriptions {{}[]} - массив подписок
 * @return {{nodes: unknown[], branches: unknown[]}} - разделенные узлы первого и второго уровня
 */
const getDeMappedSubscriptions = (subscriptions) => {
  if (!subscriptions || !isArray(subscriptions)) {
    throw new Error('getDeMappedSubscriptions: subscriptions должны быть массивом');
  }

  const nodes = uniq(subscriptions.map((item) => item.nodeId));
  const branches = uniq(subscriptions.map((item) => item.branchId));

  return {
    nodes,
    branches,
  };
};

export default {
  name: 'News',
  components: {
    Wysiwyg,
  },
  data() {
    return {
      loading: false,
      confirmModalShowed: false,
      editModalShowed: false,
      itemToDelete: null,
      editedId: -1,
      itemDefault,
      itemToEdit: itemDefault,
      isSubscriptionBranchesLoading: false,
      selectedSubscriptionsNodes: [],
      selectedSubscriptionsBranches: [],
      subscriptionsBranches: [],
      columns: [
        {
          title: 'ID',
          key: 'id',
        },
        {
          title: 'Заголовок',
          key: 'title',
        },
        {
          title: 'Описание',
          key: 'previewContent',
        },
        {
          title: 'Начало публикации',
          key: 'startDatetime',
        },
        {
          title: 'Конец публикации',
          key: 'finishDatetime',
        },
        {
          title: 'Опубликовано',
          key: 'isPublic',
        },
        {
          title: '',
          key: 'actions',
        },
      ],
      rows: [],
      servicesSearchResults: [],
      nodeList: [],
      searchLoading: false,
      currentPage: 1,
      pageCount: 0,
    };
  },
  computed: {
    isEditMode() {
      return this.editedId > -1;
    },
    formTitle() {
      return this.isEditMode
        ? 'Редактирование новости'
        : 'Добавление новости';
    },
    isSubscriptionsBranchesSelectShowed() {
      return this.selectedSubscriptionsNodes.length === 1 && !!this.subscriptionsBranches.length;
    },
    subscriptions() {
      return getMappedSubscriptions(this.selectedSubscriptionsNodes, this.selectedSubscriptionsBranches);
    },
  },
  methods: {
    ...mapActions('system', ['setLoading']),

    onEditModeAddFile(fileList) {
      this.itemToEdit.fileObj.addedFiles = [...this.itemToEdit.fileObj.addedFiles, ...fileList];
    },

    async onEditModeDeleteFile(file) {
      await this.$nextTick();
      // если удаляется только что загруженный файл (не с сервера)
      if (file instanceof File) {
        // EsmpUpload возвращает актуальный список файлов на данный момент,
        // поэтому просто фильтруем его, оставляя только сущности типа File
        this.itemToEdit.fileObj.addedFiles = cloneDeep(this.itemToEdit.fileObj.files).filter((item) => !item.id);
      } else {
        this.itemToEdit.fileObj.deletedFilesIds.push(file.id);
      }
    },

    onUploadMainImage(res) {
      this.itemToEdit.mainImageUrl = res?.url;
    },
    getNews() {
      this.loading = true;
      this.$API.news.getNews({
        ignoreSubscriptions: true,
        page: this.currentPage - 1,
        size: NEWS_PER_PAGE,
      }).then(({ data }) => {
        this.rows = data.content;
        this.pageCount = data.totalPages;
      }).finally(() => {
        this.loading = false;
      });
    },
    showConfirmModal(item) {
      this.itemToDelete = item;
      this.confirmModalShowed = true;
    },
    deleteNewsItem() {
      this.setLoading({ key: 'app', value: true });
      this.$API.news.deleteNewsItem(this.itemToDelete.id).then(() => {
        this.rows = this.rows.filter((el) => el.id !== this.itemToDelete.id);
        this.itemToDelete = null;
      }).finally(() => {
        this.setLoading({ key: 'app', value: false });
      });
    },
    editOrCreateNewsItem(item) {
      this.servicesSearchResults = [];
      this.editedId = item?.id ?? -1;
      this.selectedSubscriptionsNodes = item
        ? getDeMappedSubscriptions(item.subscriptions).nodes
        : [];
      this.selectedSubscriptionsBranches = item
        ? getDeMappedSubscriptions(item.subscriptions).branches
        : [];
      this.itemToEdit = item ? {
        id: item.id,
        title: item.title,
        mainImageUrl: item.mainImageUrl,
        content: item.content,
        isPublic: item.isPublic,
        isMain: item.isMain,
        serviceIds: item.serviceIds.map((id) => {
          this.$API.services.getServiceInfo(id).then(({ data }) => {
            this.servicesSearchResults.push({
              id: data.id,
              name: data.name,
            });
          });
          return id.toString();
        }),
        startDatetime: new Date(item.startDatetime),
        finishDatetime: new Date(item.finishDatetime),
        fileObj: {
          files: item.files,
          addedFiles: [],
          fileObjects: [],
          deletedFilesIds: [],
        },
      } : this.itemDefault;
      this.editModalShowed = true;
    },
    saveNewsItem() {
      this.$refs.form.validate().then((valid) => {
        if (valid) {
          const form = {
            title: this.itemToEdit.title,
            mainImageUrl: this.itemToEdit.mainImageUrl,
            content: this.itemToEdit.content,
            isMain: this.itemToEdit.isMain,
            isPublic: this.itemToEdit.isPublic,
            serviceIds: this.itemToEdit.serviceIds,
            subscriptions: this.subscriptions,
            startDatetime: convertDate(this.itemToEdit.startDatetime),
            finishDatetime: convertDate(this.itemToEdit.finishDatetime),
          };
          if (this.isEditMode) {
            Object.assign(form, {
              id: this.itemToEdit.id,
              addedFiles: this.itemToEdit.fileObj.addedFiles,
              deletedFilesIds: this.itemToEdit.fileObj.deletedFilesIds,
            });
          } else {
            Object.assign(form, {
              fileObjects: this.itemToEdit.fileObj.fileObjects,
            });
          }
          this.setLoading({ key: 'app', value: true });
          this.$API.news.editOrCreateNewsItem(form.id, modelToFormData(form)).then(() => {
            this.getNews();
            this.editModalShowed = false;
          }).finally(() => {
            this.setLoading({ key: 'app', value: false });
          });
        } else {
          this.$EsmpNotify.$show('Проверьте правильность заполнения полей', 'error');
        }
      });
    },
    isPublicChange(item, isPublic) {
      this.loading = true;
      const newsItem = {
        id: item.id,
        title: item.title,
        mainImageUrl: item.mainImageUrl,
        content: item.content,
        isMain: item.isMain,
        isPublic,
        serviceIds: item.serviceIds,
        subscriptions: item.subscriptions,
        startDatetime: convertDate(item.startDatetime),
        finishDatetime: convertDate(item.finishDatetime),
        addedFiles: [],
        deletedFilesIds: [],
      };
      this.$API.news.editOrCreateNewsItem(newsItem.id, modelToFormData(newsItem)).then().finally(() => {
        this.loading = false;
      });
    },
    getLocationNodes() {
      this.$API.admin.getLocationNodes().then(({ data }) => {
        this.nodeList = data;
      });
    },
    searchServices(query) {
      if (query && query.length > 2) {
        this.searchLoading = true;
        this.$API.services.simpleSearch(query).then(({ data }) => {
          this.servicesSearchResults = data;
        }).finally(() => {
          this.searchLoading = false;
        });
      } else {
        this.servicesSearchResults = [];
      }
    },
    async getNodeBranches(nodes) {
      if (nodes.length === 1) {
        try {
          this.isSubscriptionBranchesLoading = true;
          const { data: branches } = await this.$API.admin.getBranches(nodes[0]);

          this.subscriptionsBranches = branches;
        } catch {
          const node = this.nodeList.find((item) => item.id === nodes[0]);

          this.$EsmpNotify.$show(`Ошибка получения веток для узла "${node.name}"`, 'error');
        } finally {
          this.isSubscriptionBranchesLoading = false;
        }
      } else {
        this.subscriptionsBranches = [];
        this.selectedSubscriptionsBranches = [];
      }
    },
    minStartDate(date) {
      if (this.itemToEdit.finishDatetime) {
        return date.getTime() > this.itemToEdit.finishDatetime;
      }
      return null;
    },
    minEndDate(date) {
      if (this.itemToEdit.startDatetime) {
        return date.getTime() <= this.itemToEdit.startDatetime - 24 * 60 * 60 * 1000;
      }
      return null;
    },
    removeMainImage() {
      this.itemToEdit.mainImageUrl = '';
    },
  },
  created() {
    this.getNews();
    this.getLocationNodes();
  },
  watch: {
    currentPage() {
      this.getNews();
    },
  },
};
</script>
<style lang="scss">
.news {
  &__pagination {
    margin-top: 20px;
  }
  &__actions {
    display: flex;
    flex-direction: row;
    &-item {
      cursor: pointer;
      margin: 0 0 0 10px;
      &:first-child {
        margin: 0;
      }
    }
  }
  &__main-img-wrapper {
    position: relative;

    .esmp-button {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
  &__main-img {
    max-width: 200px;
  }
  &__error {
    padding: 0 $base-gutter;
    color: $error-color;
  }
}

.form-item {
  margin: 20px 0 0 0;
  &:first-child {
    margin: 0;
  }
}
</style>
